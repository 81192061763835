import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "useaxios"
    }}>{`useAxios`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`useAxios`}</inlineCode>{` hook can be used to make an axios request when your component renders. It provides a clean, simple API to allow you to configure and handle your request flow.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useAxios } from 'use-axios-client';

export default () => {
  const { data, error, loading } = useAxios({
    url: 'https://example/api',
  });

  return (
    <>
      {loading && <div>Loading...</div>}
      {error && <div>{error.message}</div>}
      {data && <div>{data}</div>}
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "request"
    }}>{`Request`}</h2>
    <p>{`You can refer to the `}<a parentName="p" {...{
        "href": "https://github.com/axios/axios#request-config"
      }}>{`Request Config`}</a>{` section of the axios documentation to see an exhaustive list of all request options.`}</p>
    <p>{`If needed, these additional options can be passed to the hook to configure your request.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`axiosInstance`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AxiosInstance`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional axios instance to be used when making the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ssrData`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`any`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Data optionally passed in from server-side rendering.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "response"
    }}>{`Response`}</h2>
    <p>{`These response values are returned by the `}<inlineCode parentName="p">{`useAxios`}</inlineCode>{` hook and can be used to handle each state of your request.`}</p>
    <p>{`It also provides several functions that allow you to interact with the request in an imperative way if needed.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`any`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The data response provided by the server.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Error`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An error message when a response is non-2xx.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The loading state of the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cancel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function that can be called to cancel an inflight request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`refetch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function that can be called to trigger a new request.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      